import * as React from 'react';
import { useSpinner } from 'ui/hooks/spinner';
import {
    PageableProcessMeasurements,
    ProcessMeasurementService,
    type ProcessMeasurementStatistic,
    ProcessMeasurementStatisticsService,
    ProcessMeasurementType,
    type ProcessRef,
    ProcessService,
    Status,
} from 'ui/api/gen';
import { formatDateToISOFormat, secondsToHMS } from 'ui/utils/date';
import { Button, Chip, IconButton, Link, TextField, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddProcessMeasurement from 'ui/pages/processmeasurements/AddProcessMeasurement';
import { PROCESS_MEASUREMENT_TYPE } from 'ui/utils/enums';
import InfoIcon from '@mui/icons-material/Info';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { useErrorHandler } from 'ui/utils/hooks';
import TableComponent from 'ui/components/grid/TableComponent';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ApplicationContext } from 'ui/context/ApplicationContext';

const Container = styled('div')`
    margin: 5px;
`;

const Text = styled('span')`
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
`;

const ProcessMeasurements = ({ process }) => {
    const [show, setShow] = React.useState(false);
    const [processes, setProcesses] = React.useState<ProcessRef[]>([]);
    const [filters, setFilters] = React.useState({
        pageIndex: 0,
        page: 0,
        pageSize: 10,
        sort: 'createdAt,asc',
        columnFilters: [{ id: 'processId', value: process?.id }],
    });
    const [pageable, setPageable] = React.useState<PageableProcessMeasurements>();

    const { state } = React.useContext(ApplicationContext);

    const { organization } = state;

    const navigate = useNavigate();
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const [statistics, setProcessStatistics] = React.useState<ProcessMeasurementStatistic[]>([]);

    const toggleOverlay = React.useCallback(() => {
        setShow((value) => !value);
    }, []);

    const handleNavigate = React.useCallback(
        ({ original: { id } }) => {
            navigate(`/measurements/${id}`);
        },
        [navigate],
    );

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const update = async () => {
                try {
                    await ProcessMeasurementService.deleteProcessMeasurements(id);
                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const response = await ProcessMeasurementService.getProcessesMeasurements(
                        pageSize,
                        pageIndex,
                        sort,
                        columnFilters.find((i) => i.id === 'completed')?.value,
                        columnFilters.find((i) => i.id === 'processId')?.value,
                        columnFilters.find((i) => i.id === 'type')?.value,
                    );
                    setPageable(response);
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };

            withSpinner(update);
        },
        [withSpinner, handleError, filters],
    );

    const actions = React.useMemo(
        () => ({
            PREVIEW: { label: 'Прегледај', callback: handleNavigate },
            DELETE: { label: 'Избриши', callback: handleDelete },
        }),
        [handleNavigate, handleDelete],
    );

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'processId',
                header: 'Процес',
                flex: 3,
                enableColumnFilter: !process,
                filterVariant: 'autocomplete',
                muiFilterAutocompleteProps: {
                    options: processes.map(({ id, name }) => ({
                        value: id,
                        label: name,
                    })),
                    renderInput: (params) => <TextField {...params} label="Процес" />,
                    disableClearable: true,
                },
                Cell: ({ row, cell }) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link onClick={() => handleNavigate(row)} color="primary" component="button">
                        {processes.find((item) => item.id === cell.getValue())?.name}
                    </Link>
                ),
            },
            {
                accessorKey: 'measuredDuration',
                type: 'number',
                enableColumnFilter: false,
                enableSorting: false,
                header: 'Измерено',
                flex: 1,
                accessorFn: ({ measuredDuration }) => secondsToHMS(measuredDuration),
            },
            {
                accessorKey: 'baselineDuration',
                enableColumnFilter: false,
                enableSorting: false,
                type: 'number',
                header: 'Очекувано',
                flex: 1,
                accessorFn: ({ baselineDuration }) => secondsToHMS(baselineDuration),
            },
            {
                accessorKey: 'difference',
                type: 'number',
                enableColumnFilter: false,
                enableSorting: false,
                header: 'Разлика',
                flex: 1,
                Cell: ({ row }) => {
                    const value = row.original.difference;

                    const isNegative = value < 0;
                    const absoluteSeconds = Math.abs(value);

                    const formattedTime = secondsToHMS(absoluteSeconds);
                    const color = isNegative ? 'green' : 'red';
                    const sign = isNegative ? '-' : '+';

                    if (!value) {
                        return null;
                    }

                    return (
                        <span style={{ color }}>
                            {sign}
                            {formattedTime}
                        </span>
                    );
                },
                Header: ({ column }) => (
                    <strong>
                        <Tooltip title="Измерено минус очекувано време">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                        <Text>{column.columnDef.header}</Text>
                    </strong>
                ),
            },
            {
                accessorKey: 'completed',
                header: 'Статус',
                flex: 1,
                filterVariant: 'checkbox',
                Cell: ({ row }) => {
                    const value = row.original.completed;
                    const label = value ? 'Комплетирано' : 'Драфт';
                    const color = value ? 'success' : undefined;

                    return <Chip color={color} label={label} />;
                },
            },
            {
                accessorKey: 'type',
                header: 'Тип на мерење',
                flex: 1,
                filterVariant: 'select',
                filterSelectOptions: [
                    {
                        value: ProcessMeasurementType.COMPLETE,
                        label: PROCESS_MEASUREMENT_TYPE[ProcessMeasurementType.COMPLETE],
                    },
                    {
                        value: ProcessMeasurementType.PARTIAL,
                        label: PROCESS_MEASUREMENT_TYPE[ProcessMeasurementType.PARTIAL],
                    },
                ],

                Cell: ({ row }) => {
                    const value = row.original.type;
                    const label = PROCESS_MEASUREMENT_TYPE[value];
                    const color = value === ProcessMeasurementType.COMPLETE ? 'success' : undefined;

                    return <Chip color={color} label={label} />;
                },
            },
            {
                accessorKey: 'createdAt',
                header: 'Датум',
                enableColumnFilter: false,
                flex: 1,
                accessorFn: ({ createdAt }) => formatDateToISOFormat(createdAt),
            },
            {
                accessorKey: 'actions',
                header: '',
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                size: 1,
                Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
            },
        ],
        [actions, handleNavigate, processes, process],
    );

    const rows = React.useMemo(
        () =>
            pageable?.result.map(({ id, type, createdAt, processId, completed }) => {
                const date = formatDateToISOFormat(createdAt);
                const statistic = statistics.find((item) => item.processMeasurementId === id);

                return {
                    id,
                    type,
                    processId,
                    completed,
                    createdAt: date,
                    measuredDuration: statistic?.activityMeasurementTime || 0,
                    baselineDuration: statistic?.activityBaselineTime || 0,
                    difference: (statistic?.activityMeasurementTime || 0) - (statistic?.activityBaselineTime || 0),
                };
            }),
        [statistics, pageable, processes],
    );

    useDeepCompareEffect(() => {
        const initialize = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;
                const response = await ProcessMeasurementService.getProcessesMeasurements(
                    pageSize,
                    pageIndex,
                    sort,
                    organization?.id,
                    columnFilters.find((i) => i.id === 'completed')?.value,
                    columnFilters.find((i) => i.id === 'processId')?.value,
                    columnFilters.find((i) => i.id === 'type')?.value,
                );
                setPageable(response);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, filters, handleError, organization]);

    React.useEffect(() => {
        const initialize = async () => {
            try {
                const processRefs = await ProcessService.getProcessesRef(organization?.id);
                setProcesses(processRefs);

                const statisticsResponse: Array<ProcessMeasurementStatistic> =
                    await ProcessMeasurementStatisticsService.getProcessMeasurementStatistics(organization?.id);
                setProcessStatistics(statisticsResponse);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, handleError, organization]);

    const onSubmit = React.useCallback(
        async (values) => {
            const save = async () => {
                try {
                    await ProcessMeasurementService.postProcessesMeasurements(values.processId, values);

                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const response = await ProcessMeasurementService.getProcessesMeasurements(
                        pageSize,
                        pageIndex,
                        sort,
                        organization?.id,
                        columnFilters.find((i) => i.id === 'completed')?.value,
                        columnFilters.find((i) => i.id === 'processId')?.value,
                        columnFilters.find((i) => i.id === 'type')?.value,
                    );
                    setPageable(response);
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    toggleOverlay();
                }
            };

            withSpinner(save);
        },
        [withSpinner, toggleOverlay, filters, handleError, organization?.id],
    );

    console.log(filters?.pageSize);
    const activeProcesses = React.useMemo(() => processes.filter((p) => p.status === Status.ACTIVE), [processes]);
    return (
        <Container>
            <Button
                variant="contained"
                color="secondary"
                disabled={process && process?.status !== Status.ACTIVE}
                onClick={toggleOverlay}
                style={{ margin: '10px 0' }}
                startIcon={<AddIcon />}
            >
                Додади мерење
            </Button>
            <AddProcessMeasurement
                open={show}
                processId={process?.id}
                handleClose={toggleOverlay}
                onSubmit={onSubmit}
                processes={activeProcesses}
            />
            <TableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                rowCount={pageable?.page?.totalElements}
                pageCount={pageable?.page?.totalPages}
                rows={rows}
                columns={columns}
                setFilters={setFilters}
                initialColumnFilters={filters.columnFilters}
            />
        </Container>
    );
};

export default ProcessMeasurements;

import React from 'react';
import { Form } from 'react-final-form';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { makeValidate, TextField } from 'mui-rff';
import Divider from '@mui/material/Divider';
import { Activity } from 'ui/api/gen';
import { number, object, string } from 'yup';
import ActivityDuration from 'ui/pages/processes/ActivityDuration';
import ActivitySwitch from 'ui/pages/processes/ActivitySwitch';

type Props = {
    open: boolean;
    root?: Activity;
    selectedItem?: Activity;
    onClose: () => void;
    onSubmit: (values: any) => void;
};

const AddActivity = ({ open, onClose, onSubmit, root, selectedItem }: Props) => {
    const addTitle = root ? `Додади подниво: ${root?.name}` : 'Додади фаза';
    const title = selectedItem ? 'Ажурирај' : addTitle;

    const schema = object({
        name: string().required(),
        duration: number().required(),
    });

    const validate = makeValidate(schema);

    console.log(selectedItem);
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <Divider />
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    initialValues={selectedItem || { parentId: root?.id, duration: 0 }}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Назив"
                                        style={{ marginBottom: '10px' }}
                                        name="name"
                                    />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="description"
                                        label="Опис"
                                        style={{ marginBottom: '10px' }}
                                        name="description"
                                    />

                                    <Grid item xs={12}>
                                        <ActivityDuration />
                                    </Grid>
                                    {selectedItem ? (
                                        selectedItem?.children?.length === 0
                                    ) : (
                                        <Grid item xs={12}>
                                            <ActivitySwitch selectedItem={selectedItem} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <br />
                            <Button onClick={handleSubmit} color="secondary" variant="contained">
                                Сочувај
                            </Button>
                            <span>&nbsp;</span>
                            <Button onClick={onClose} color="inherit" variant="contained">
                                Откажи
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddActivity;

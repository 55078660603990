import * as React from 'react';
import { useCountUp } from 'use-count-up';
import { Box, CircularProgress, Fab, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ActivityMeasurementService, MeasurementType } from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { differenceInSeconds } from 'date-fns';
import { useErrorHandler } from 'ui/utils/hooks';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

const Container = styled('div')`
    display: flex;
`;

const Measurement = ({ employee, activityId, processMeasurementId, activityMeasurement, measurable, handleUpdate }) => {
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const [isLoading, setLoading] = React.useState(activityMeasurement?.createdAt && !activityMeasurement?.measuredAt);

    const seconds = React.useMemo(
        () =>
            activityMeasurement?.createdAt
                ? differenceInSeconds(new Date(), new Date(activityMeasurement?.createdAt))
                : 0,
        [activityMeasurement?.createdAt],
    );

    const { value } = useCountUp({
        isCounting: isLoading,
        start: 0,
    });

    const handleStartTimer = React.useCallback(async () => {
        const save = async () => {
            setLoading(true);
            try {
                await ActivityMeasurementService.postProcessesMeasurementsActivitiesMeasurements(
                    processMeasurementId,
                    activityId,
                    // @ts-ignore
                    {
                        measurementType: MeasurementType.AUTOMATIC,
                        employeeId: employee?.id,
                    },
                );

                handleUpdate();
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };

        withSpinner(save);
    }, [withSpinner, activityId, processMeasurementId, handleUpdate, employee, handleError]);

    const handleComplete = React.useCallback(async () => {
        const save = async () => {
            try {
                await ActivityMeasurementService.patchProcessesMeasurementsActivitiesMeasurements(
                    activityMeasurement?.id,
                    {
                        duration: value as number,
                    },
                );

                handleUpdate();
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            } finally {
                setLoading(false);
            }
        };

        withSpinner(save);
    }, [handleUpdate, value, withSpinner, activityMeasurement?.id, handleError]);

    if (!measurable) {
        return null;
    }
    return (
        <Container>
            {isLoading && (
                <Box display="flex" justifyContent="center" alignItems="center" padding="5px">
                    <CircularProgress />
                    <Typography position="absolute">{value}s</Typography>
                </Box>
            )}

            {!activityMeasurement?.measuredAt && !isLoading && (
                <Fab color="success" aria-label="add" onClick={handleStartTimer} size="small">
                    <PlayArrowIcon />
                </Fab>
            )}

            {isLoading && (
                <Fab color="error" aria-label="add" onClick={handleComplete} size="small">
                    <StopIcon />
                </Fab>
            )}
        </Container>
    );
};

export default Measurement;

import { reducerWithActionMappings } from 'ui/context/reducers';
import { Action } from 'ui/context/types';
import { initialState } from 'ui/context/initialState';
import { BackendUser } from 'ui/api/gen';
import { SET_USER_DETAILS } from 'ui/context/user/actions';
import { SetUserDetailsActions } from 'ui/context/user/types';

const setUserDetails = (
  state: BackendUser,
  action: SetUserDetailsActions,
): BackendUser => action.user;

export const userDetailsReducer = (
  state: BackendUser,
  action: Action,
): BackendUser =>
  reducerWithActionMappings(
    {
      [SET_USER_DETAILS]: setUserDetails,
    },
    initialState.user,
    // @ts-ignore
  )(state, action);
